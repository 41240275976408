import React from 'react';
import { Route } from 'react-router-dom';

import DefaultLayout from './Layout';

export function HomeRoute({ component: Component, ...rest }) {
   return (
      <Route
         {...rest}
         render={(props) => (
            <DefaultLayout>
               <Component {...props} />
            </DefaultLayout>
         )}
      />
   );
}

export default ({ component: Component, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => (
            <DefaultLayout>
               <Component {...props} />
            </DefaultLayout>
         )}
      />
   );
};
