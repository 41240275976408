import React, { memo, useState, useRef } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import M from 'materialize-css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

const URL = window.location.href.replace(/([a-zA-Z+.\-]+):\/\/([^\/]+):([0-9]+)\//, '$1://$2/');
const API_URL = URL + 'cfc/api/';

const SimpleInputContainer = memo(
  ({ register, errors, className, name, label, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <input type="text" name={name} id={name} ref={register(required && required)} />
        <label htmlFor={name}>{label}</label>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty && prevProps.children === nextProps.children
);

const CustomRadioContainer = memo(
  ({ register, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <p style={{ fontSize: '16px', margin: '0' }}>
          {label}
          <br />
          {options.map((el, i) => (
            <label key={i}>
              <input ref={register} id={`${name}_${i}`} type="radio" name={name} value={el.value} />
              <span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>{el.label}</span>
            </label>
          ))}
        </p>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

const CustomMultiSelectContainer = memo(
  ({ register, setValue, trigger, unregister, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    const selectEl = useRef(null);
    const [select, setSelect] = useState(null);

    React.useEffect(() => {
      try {
        setSelect(M.FormSelect.init(selectEl.current, {}));
      } catch (e) {}
    }, [selectEl, options]);

    React.useEffect(() => {
      register(name, required);
      return () => {
        unregister(name);
      };
    }, []);

    const handleChange = (v) => {
      setValue(name, select.getSelectedValues());
      // trigger();
    };

    const handleSetRef = (ref) => {
      selectEl.current = ref;
    };

    return (
      <div className={`input-field ${className}`}>
        <select name={name} ref={handleSetRef} multiple onChange={handleChange}>
          <option disabled value="">
            Select {name.substring(0, name.length - 1)}({name.charAt(name.length - 1)})
          </option>
          {options.map((opt, x) => (
            <option key={x} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <label>{label}</label>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty && prevProps.children === nextProps.children
);

const SimpleTextAreaContainer = memo(
  ({ register, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <textarea className="materialize-textarea" name={name} style={{ height: '100px' }} ref={register}></textarea>
        <label>{label}</label>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

const IFrameMap = memo(
  ({ map, children, ...rest }) => {
    console.log({ map });
    return <iframe src={map} width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>;
  },
  (prevProps, nextProps) => prevProps.map === nextProps.map
);

const PersonalInformation = ({ children, ...rest }) => {
  const [map, setMap] = useState(null);
  const methods = useFormContext();
  const { errors, getValues } = methods;

  const shifts = [
    {
      label: 'Day Shift ( ~6AM - 4PM )',
      value: 'Day Shift',
    },
    {
      label: 'Evening Shift ( ~4PM - 11PM )',
      value: 'Evening Shift',
    },
    {
      label: 'Overnight Shift ( Midnight - 6AM )',
      value: 'Overnight Shift',
    },
  ];
  const locations = [
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.4088088591275!2d-80.96688878426433!3d32.03112428120359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb78752e931469%3A0xdeeb3288d429cc7!2sChu's%20Mart%20%23103!5e0!3m2!1sen!2sus!4v1625070098448!5m2!1sen!2sus",
      label: 'Savannah - Johnny Mercer Blvd Location',
      value: 'Store 103 - Johnny Mercer Blvd Location',
    },
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.269819713035!2d-81.1798516842632!3d32.088992581185366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fba033cd3f6683%3A0xaa78fba4ec5652da!2sChu's%20Convenience%20Mart!5e0!3m2!1sen!2sus!4v1625070903692!5m2!1sen!2sus",
      label: 'Garden City - Hwy 80 West Location',
      value: 'Store 106 - Hwy 80 West Location',
    },
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3384.0786440062566!2d-81.1634252848379!3d31.985883481218032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb74cc81cf76e9%3A0x86af32d7d54aa618!2sChu's%20Mart%20%23114!5e0!3m2!1sen!2sus!4v1625071398777!5m2!1sen!2sus",
      label: 'Savannah - Middleground Road Location',
      value: 'Store 114 - Middleground Road Location',
    },
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3386.1220281431674!2d-81.33049768483922!3d31.93044418123606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb0f81d1954351%3A0x58d6b2d412234df0!2sChu's%20Convenience%20Mart!5e0!3m2!1sen!2sus!4v1625071452892!5m2!1sen!2sus",
      label: 'Richmond Hill - Hwy 17 South Location',
      value: 'Store 116 - Hwy 17 South Location',
    },
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.476601795317!2d-81.11352678483678!3d32.029288681204164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb759a539bbbc3%3A0xbebc46940a051188!2sChu's%20Mart%20%23119!5e0!3m2!1sen!2sus!4v1625071497915!5m2!1sen!2sus",
      label: 'Savannah - West Derenne Location',
      value: 'Store 119 - West Derenne Location',
    },
    // {
    //    map:
    //       "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d216538.47004556202!2d-80.9864483!3d32.0039085!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb75a8abe813bf%3A0x2bb5c5ea483ec225!2sChu's%20Mart%20%23120!5e0!3m2!1sen!2sus!4v1625071545978!5m2!1sen!2sus",
    //    label: 'Store 120 - Abercorn Location',
    //    value: 'Store 120 - Abercorn Location',
    // },
    {
      map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13533.642966746414!2d-80.8551204!3d32.0039858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb7f909c7df7e3%3A0x623bc5c448c34c7f!2sChu's%20Mart%20%23121!5e0!3m2!1sen!2sus!4v1625071590911!5m2!1sen!2sus",
      label: 'Tybee Island - Tybee Location',
      value: 'Store 121 - Tybee Location',
    },
  ];
  const locationCities = [
    {
      label: 'Savannah',
      value: 'Savannah',
    },
    {
      label: 'Garden City',
      value: 'Garden City',
    },
    {
      label: 'Richmond Hill',
      value: 'Richmond Hill',
    },
    {
      label: 'Islands',
      value: 'Islands',
    },
  ];
  const locWatch = methods.watch('loc');

  React.useEffect(() => {
    setMap(() => {
      const ops = locations.filter((el) => el.value === locWatch);

      return ops.length > 0 ? ops[0].map : null;
    });
  }, [locWatch]);

  console.log(getValues('locations'));
  console.log({ errors });

  return (
    <>
      <h1>EMPLOYMENT APPLICATION</h1>
      <div className="card">
        <div className="card-content">
          <fieldset>
            <legend>Personal Information</legend>
            <div className="row">
              <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Name *" name="name" required={{ required: 'Your name is required' }}>
                <>{errors && errors.name && <span className="red-text helper-text">{errors.name.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Address *" name="address" required={{ required: 'Your address is required' }}>
                <>{errors && errors.address && <span className="red-text helper-text">{errors.address.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="City *" name="city" required={{ required: 'City is required' }}>
                <>{errors && errors.city && <span className="red-text helper-text">{errors.city.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="State *" name="state" required={{ required: 'State is required' }}>
                <>{errors && errors.state && <span className="red-text helper-text">{errors.state.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Zip *" name="zip" required={{ required: 'Zip is required' }}>
                <>{errors && errors.zip && <span className="red-text helper-text">{errors.zip.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Phone *" name="phone" required={{ required: 'Phone is required' }}>
                <>{errors && errors.phone && <span className="red-text helper-text">{errors.phone.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="SS# *" name="ssn" required={{ required: 'SSN is required' }}>
                <>{errors && errors.ssn && <span className="red-text helper-text">{errors.ssn.message}</span>}</>
              </SimpleInputContainer>
              <CustomMultiSelectContainer
                {...methods}
                options={locationCities}
                className="input-field col s12 m6"
                label="Locations available to work"
                name="locations"
                required={{
                  validate: (v) => !!(v && v.length > 0),
                }}
              >
                <>{errors && errors.locations && <span className="red-text helper-text">Locations is required</span>}</>
              </CustomMultiSelectContainer>
              <CustomMultiSelectContainer {...methods} options={shifts} className="input-field col s12 m6" label="Shifts available to work" name="shifts" required={{ validate: (v) => !!(v && v.length > 0) }}>
                <>{errors && errors.shifts && <span className="red-text helper-text">Shifts is required</span>}</>
              </CustomMultiSelectContainer>

              {/* <CustomSelectContainer {...methods} options={locations} className="input-field col s12" label="Change to see a google map of location" name="loc" />
                     {map && (
                        <div className="col s12">
                           <IFrameMap map={map} />
                        </div>
                     )} */}

              {/* <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Position Applying For *" name="position" required={{ required: 'Position is required' }}>
                        <>{errors && errors.name && <span className="red-text helper-text">{errors.name.message}</span>}</>
                     </SimpleInputContainer> */}

              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>Person to contact in case of emergency:</p>
              </div>

              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Name *" name="contact.name" required={{ required: 'Emergency Contact is required' }}>
                <>{errors && errors.contact && errors.contact.name && <span className="red-text helper-text">{errors.contact.name.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Relationship *" name="contact.relationship" required={{ required: 'Emergency Contact Relationship is required' }}>
                <>{errors && errors.contact && errors.contact.relationship && <span className="red-text helper-text">{errors.contact.relationship.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Address *" name="contact.address" required={{ required: 'Emergency Contact Address is required' }}>
                <>{errors && errors.contact && errors.contact.address && <span className="red-text helper-text">{errors.contact.address.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="City *" name="contact.city" required={{ required: 'Emergency Contact City is required' }}>
                <>{errors && errors.contact && errors.contact.city && <span className="red-text helper-text">{errors.contact.city.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="State *" name="contact.state" required={{ required: 'Emergency Contact State is required' }}>
                <>{errors && errors.contact && errors.contact.state && <span className="red-text helper-text">{errors.contact.state.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="Zip *" name="contact.zip" required={{ required: 'Emergency Contact Zip is required' }}>
                <>{errors && errors.contact && errors.contact.zip && <span className="red-text helper-text">{errors.contact.zip.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="Phone *" name="contact.phone" required={{ required: 'Emergency Contact Phone is required' }}>
                <>{errors && errors.contact && errors.contact.phone && <span className="red-text helper-text">{errors.contact.phone.message}</span>}</>
              </SimpleInputContainer>
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Education</legend>
            <div className="row">
              <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="High School *" name="highschool" required={{ required: 'High School field is required' }}>
                <>{errors && errors.highschool && <span className="red-text helper-text">{errors.highschool.message}</span>}</>
              </SimpleInputContainer>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m6 l6"
                label="Did you graduate? *"
                name="graduate"
                required={{ required: 'Did you graduate is required' }}
              >
                <>{errors && errors.graduate && <span className="red-text helper-text">{errors.graduate.message}</span>}</>
              </CustomRadioContainer>
              <SimpleInputContainer {...methods} className="input-field col s12" label="College/Trade School" name="college">
                <>{errors && errors.college && <span className="red-text helper-text">{errors.college.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12" label="Courses and Degrees" name="degrees">
                <>{errors && errors.degree && <span className="red-text helper-text">{errors.degree.message}</span>}</>
              </SimpleInputContainer>
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Employment History</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>Please list the last 3 jobs if less than 5 years with the most recent first.</p>
              </div>
              {[1, 2, 3].map((el, i) => (
                <div className="row" key={i} style={{ borderBottom: i < 2 ? '1px dashed grey' : '' }}>
                  <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Company Name" name={`companyname[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="City/State" name={`city_state[${i}]`} />
                  <CustomRadioContainer
                    {...methods}
                    options={[
                      { value: 'Temporary', label: 'Temporary' },
                      { value: 'Permanent', label: 'Permanent' },
                    ]}
                    className="input-field col s12 m4"
                    label=""
                    name={`temp_perm[${i}]`}
                  />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="If temporary, what agency or company" name={`agency_company[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Title" name={`title[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Salary" name={`salary[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Start Date" name={`start_date[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="End Date" name={`end_date[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Duties" name={`duties[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Supervisor" name={`supervisor[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4" label="Phone" name={`job_phone[${i}]`} />
                  <SimpleInputContainer {...methods} className="input-field col s12" label="Reason for leaving" name={`reason[${i}]`} />
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

const StandardInterviewForm = ({ children, ...rest }) => {
  const methods = useFormContext();
  const { errors } = methods;

  return (
    <>
      <h1>STANDARD INTERVIEW FORM</h1>
      <div className="card">
        <div className="card-content">
          <fieldset>
            <legend>Standard Interview</legend>
            <div className="row">
              <SimpleInputContainer {...methods} className="input-field col s12" label="1. How did you hear of us?" name="q1" />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="2. Have you ever filled out an application or worked for our firm before?"
                name="q2"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="3. Do you have a working telephone?"
                name="q3"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="4. Do you have reliable transportation?"
                name="q4"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m6"
                label="5. Do you have a valid driver's license?"
                name="q5"
              />
              <SimpleInputContainer {...methods} className="input-field col s12 m6" label="State issued from?" name="q5_state" />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="6. Do you have (with you) two legal ID's for Federal I-9 information?"
                name="q6"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m6"
                label="7. Will you work any hours available to you?"
                name="q7"
              />
              <SimpleInputContainer {...methods} className="input-field col s12 m6" label="If not, what hours are you available to work?" name="q7_available" />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="8. Will you work weekends?"
                name="q8"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="9. Are you willing to take a drug and alcohol screen?"
                name="q9"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="10. Will you submit to a criminal background check?"
                name="q10"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="11. Are you over 18 years of age?"
                name="q11"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="12. Have you ever been fired or asked to resign from any job within the past seven(7) years?"
                name="q12"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="13. Have you ever taken any merchandise, money, or property from any employer?"
                name="q13"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="14. Have you been convicted, or pled nolo, or received deferred adjudication for a felony?"
                name="q14"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="15. Have you been convicted, or pled nolo, or received deferred adjudication for a misdemeanor?"
                name="q15"
              />
              <SimpleTextAreaContainer {...methods} className="input-field col s12" label="Please explain any YES answer to questions 12, 13, 14, 15 below:" name="q_explain" />
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

const JobDescriptionEssentialFunctions = ({ children, ...rest }) => {
  const methods = useFormContext();
  const { errors } = methods;

  return (
    <>
      <h1>JOB DESCRIPTION / ESSENTIAL FUNCTIONS</h1>
      <div className="card">
        <div className="card-content">
          <fieldset>
            <legend>JOB DESCRIPTION</legend>
            <div className="row">
              <p style={{ fontSize: '16px', margin: '0' }}>
                Once a conditional job offer is made please be aware all persons may be required to furnish health condition information and if necessary submit to an examination by a company-designated physician. This information will be used to
                determine appropriate job placement. It shall not be used to disqualify an otherwise qualified person who may have a mental or physical disability.
                <br />
                <br />
                The following are physical requirements pertaining to the job for which you are applying. These physical requirements are essential function of the job and are in addition to the skills certifications and years of experience or other
                qualifications required to perform the job(s) for which you have applied.
                <br />
                <br />
                <b>Note:</b> Only those essential function relevant to the job(s) for which you have applied will be checked below. Are you able to perform the tasks or functions that are checked?
              </p>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Work at and view a video displayed terminal for an eight or more shift?"
                name="qjob1"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Stand for long periods of time during your shift?"
                name="qjob2"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Grip grasp and twist using your hands and wrists?"
                name="qjob3"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Lift and/or carry up to 25lbs regularly during your shifts?"
                name="qjob4"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Climb stairs with loads up to 25lbs during your shift?"
                name="qjob5"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Reach over your head with 10-25lbs loads regularly during your shift if required?"
                name="qjob6"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Understand hazard communication and safety information?"
                name="qjob7"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12"
                label="Are you physically able to type or work at a keyboard or typewriter most of the day if required? (This is not asking if you can type.)"
                name="qjob8"
              />
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>
                  Based on the information discussed and/or received I feel as though I
                  <label>
                    <input ref={methods.register} id="can_perform_1" type="radio" name="can_perform" value="YES" />
                    <span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>CAN</span>
                  </label>
                  <label>
                    <input ref={methods.register} id="can_perform_2" type="radio" name="can_perform" value="NO" />
                    <span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>CAN NOT</span>
                  </label>
                  perform the essential functions fo the job we've discussed.
                </p>
              </div>
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>
                  * If you cannot perform one or more of the job requirements noted above and you feel we can modify any part or the job and/or schedule to enable you to do the work please explain in the space below.
                  <br />
                  <br />* Job modification will be addressed on a case-by-case basis.
                </p>
              </div>
              <SimpleTextAreaContainer {...methods} className="input-field col s12" label="Type your answer here" name="qjob_modifications" />
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

const FileUploader = ({ files, setFiles }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);

  const handleOnChange = React.useCallback(
    (e) => {
      if (!executeRecaptcha) return;
      if (loading) return;

      e.persist();

      setLoading(true);
      (async () => {
        try {
          const token = await executeRecaptcha('fileUpload');
          const data = new FormData();

          console.log({ e });
          data.append('file', e.target.files[0]);
          data.append('token', token);

          axios({
            url: `${API_URL}job.cfc?method=upload`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data,
          })
            .then((r) => r.status === 200 && r.data)
            .then((d) => {
              if (d.success) {
                const f = [...files, `${URL}assets/files/${d.link}`];
                console.log({ f });
                setFiles([...files, `${URL}assets/files/${d.link}`]);

                M.toast({ html: 'File uploaded successfully' || d.messages[0] });
              } else {
                d.messages.map((e) => {
                  M.toast({ html: e });
                });
              }
            })
            .finally((f) => {
              setLoading(false);
              document.getElementById('display_fn').value = '';
              document.getElementById('file').value = '';
            })
            .catch((e) => console.error({ e }));
        } catch (e) {
          console.error({ e });
        }
      })();
    },
    [executeRecaptcha, files, loading]
  );

  return (
    <div className="card">
      <div className="card-content">
        <fieldset>
          <legend>FILE UPLOADS</legend>
          <div className="row">
            <div className="col s12">
              <p>You can upload your resume/cover letter PDF documents here</p>
            </div>
            {files.length > 0 && (
              <>
                <div className="collection">
                  {files.map((e, i) => (
                    <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" className="collection-item" rel="" key={i} href={e}>
                      {e.split('/')[e.split('/').length - 1]}
                    </a>
                  ))}
                </div>
              </>
            )}
            <div className="col s12">
              <div className="file-field input-field">
                <div className="btn">
                  <span>File</span>
                  <input id="file" name="file" type="file" onChange={handleOnChange} accept="application/pdf" />
                </div>
                <div className="file-path-wrapper">
                  <input id="display_fn" className="file-path validate" type="text" />
                </div>
              </div>
              {loading && (
                <div class="progress">
                  <div class="indeterminate"></div>
                </div>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

const FormWrapper = (props) => {
  const methods = useForm({ defaultValues: { temp_perm: ['', '', ''] } });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const sanitize = (v) => {
    Object.keys(v).forEach((key) => {
      if (v[key] == null) {
        v[key] = '';
      }
    });
  };

  const onSubmit = React.useCallback(
    (v) => {
      if (!executeRecaptcha) return;
      if (loading) return;

      setLoading(true);
      sanitize(v);

      v.temp_perm[0] = v.temp_perm[0] == null ? '' : v.temp_perm[0];
      v.temp_perm[1] = v.temp_perm[1] == null ? '' : v.temp_perm[1];
      v.temp_perm[2] = v.temp_perm[2] == null ? '' : v.temp_perm[2];

      (async () => {
        try {
          const token = await executeRecaptcha('newApplication');

          console.log({ token });

          const data = {
            ...v,
            files,
            token,
          };

          axios({
            url: `${API_URL}job.cfc?method=process`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data,
          })
            .then((r) => r.status === 200 && r.data)
            .then((d) => {
              console.log({ d });
              if (d.success) {
                M.toast({ html: 'We have received your application. Thank you!' });
                document.getElementById('app_form').reset();
                setFiles([]);
                methods.reset();
              } else {
                d.messages.map((e) => {
                  M.toast({ html: e });
                });
              }
            })
            .catch((e) => console.error({ e }))
            .finally((f) => setLoading(false));
        } catch (e) {
          console.error({ e });
        }
      })();
    },
    [executeRecaptcha, files, loading]
  );

  return (
    <FormProvider {...methods}>
      <form id="app_form" onSubmit={methods.handleSubmit(onSubmit)}>
        <PersonalInformation />
        <StandardInterviewForm />
        <JobDescriptionEssentialFunctions />
        <FileUploader {...{ files, setFiles }} />
        <div className="row">
          <div className="center-align col s12">
            {loading ? (
              <button disabled className="waves-effect waves-light btn">
                Submit Application
              </button>
            ) : (
              <button className="waves-effect waves-light btn">Submit Application</button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormWrapper;
