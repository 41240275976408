import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/materializeOverride.scss';
import './styles/materialize.modern.admin.scss';
import './styles/app.css';
import App from './app';

ReactDOM.render(
   <React.StrictMode>
      <Router>
         <App />
      </Router>
   </React.StrictMode>,
   document.getElementById('root')
);
