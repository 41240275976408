import React from "react";
import top_nav_bg from "../../styles/top-nav-bg.jpg";

const PageWrapper = () => {
	const s = {
		header: {
			background: `url(${top_nav_bg}) top center no-repeat`,
			display: "block",
			position: "absolute",
			margin: "0 auto 6px",
			width: "100%",
			height: "61px",
			top: "56px",
			zIndex: "999",
		},
		logo: {
			position: "absolute",
			display: "block",
			width: "156px",
			height: "191px",
			zIndex: "1001",
			left: "0",
			right: "0",
			margin: "0 auto",
		},
	};

	return (
		<div className="" style={{ position: "relative", height: "117px" }}>
			<div style={s.header}>&nbsp;</div>
			<div style={s.logo}>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.chusmart.com/"
				>
					<img
						src="https://www.chusmart.com/wp-content/themes/chus/images/logo.png"
						alt="ChusMarket.com"
					/>
				</a>
			</div>
		</div>
	);
};

export default PageWrapper;
