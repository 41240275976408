import React from 'react';
import header_bg from '../styles/top-banner-bg.png';
import Header from '../components/Header';
import Footer from '../components/Footer';

export const DefaultLayout = (props) => {
   const s = {
      wrapper: { background: `url(${header_bg}) top center repeat-x`, minHeight: '100vh' },
   };

   return (
      <div id="wrapper" style={s.wrapper}>
         <Header />
         {props.children}
         <Footer />
      </div>
   );
};

export default DefaultLayout;
