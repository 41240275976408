import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import Route, { HomeRoute } from "./routes";

import HomePage from "./containers/HomePage";
import NotFoundPage from "./containers/NotFoundPage";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const { REACT_APP_RECAPTCHA_KEY } = process.env;

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const gRecaptchaVars = {
	reCaptchaKey: REACT_APP_RECAPTCHA_KEY,
};

const App = () => {
	return (
		<GoogleReCaptchaProvider {...gRecaptchaVars}>
			<Router>
				<React.Fragment>
					<ScrollToTop />
					<Switch>
						<HomeRoute exact path="/" component={HomePage} />
						<Route component={NotFoundPage} />
					</Switch>
				</React.Fragment>
			</Router>
		</GoogleReCaptchaProvider>
	);
};

export default App;
