import React from "react";
import top_nav_bg from "../../styles/chus-footer-bg.jpg";

const PageWrapper = () => {
	const s = {
		footer: {
			backgroundImage: `url(${top_nav_bg})`,
			height: "79px",
			backgroundRepeat: "repeat-x",
			textAlign: "center",
			color: "#be1e2d",
			paddingTop: "10px",
			fontSize: "15px",
			textTransform: "uppercase",
		},
	};

	return (
		<div className="" style={{ position: "relative" }}>
			<div style={s.footer}>
				<p>
					&copy; 2021 Chusmart | Crafted and Hosted by{" "}
					<a
						href="https://thesabresolution.com/"
						rel="noopener noreferrer"
						target="_blank"
						style={{ color: "#be1e2d" }}
					>
						Sabre Creative
					</a>
				</p>
			</div>
		</div>
	);
};

export default PageWrapper;
