import React from 'react';
import Form from './form';

const PageWrapper = (props) => {
   return (
      <div className="container">
         <div>
            <div className="row">
               <Form {...props} />
            </div>
         </div>
      </div>
   );
};

export default PageWrapper;
